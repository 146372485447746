import React, { Component } from 'react'

import SEO from '../components/seo'
import { Flex, Box } from '@rebass/grid'

import Title from '../components/page-title'
import Copy from '../components/page-copy'
import Row from '../components/page-content-row'
import Image from '../components/page-image-new'
import Subtitle from '../components/page-subtitle'
import ResourceCategoryList from '../components/resource-category-list'
import PageCards from '../components/page-cards'

import Image1 from '../images/resources/happy_haus_darlington_047.jpg'
import Image2 from '../images/resources/happy-haus-helensvale-10.jpg'
import Image3 from '../images/resources/happyhaus-gallery-2019-07-24-photo-strong-knockdown-1640.jpg'
import Image4 from '../images/resources/happy-haus-south-stradbroke-6.jpg'
const ogImages = [
  `https://happyhaus.com.au${Image1}`,
  `https://happyhaus.com.au${Image2}`,
  `https://happyhaus.com.au${Image3}`,
  `https://happyhaus.com.au${Image4}`,
]

const ResourceImage = 'resources/happy_haus_darlington_047.jpg'

const ResourceImage2 = 'resources/happy-haus-helensvale-10.jpg'

const ResourceImage3 =
  'resources/happyhaus-gallery-2019-07-24-photo-strong-knockdown-1640.jpg'

const ResourceImage4 = 'resources/happy-haus-south-stradbroke-6.jpg'
class Page extends Component {
  render() {
    return (
      <>
        <SEO
          title="Resources Test 1"
          description="At Ratio, we believe intelligent architecture, stunning
									interior design and carefully selected standard inclusions can
									create a new level of affordable luxury."
          images={ogImages}
        />

        <Row>
          <Flex>
            <Box width={8 / 12} px={2}>
              <Image src={ResourceImage} ratio={1 / 1.5} />
              <Title align="left">Resources Test 1</Title>
              <Copy align="left">
                <p>
                  At Ratio, we believe intelligent architecture, stunning
                  interior design and carefully selected standard inclusions can
                  create a new level of affordable luxury.
                </p>
                <p>
                  View our gallery or visit our showroom and you will see at a
                  glance what makes us different. Large picture windows, usually
                  only found in much more expensive homes, outstanding design
                  and attention to detail quickly become apparent.
                </p>
                <p>
                  Our homes offer significantly more usable space inside through
                  intelligent design and the use of sun courts that flood the
                  interiors with natural light.
                </p>
                <p>
                  Additional storage solution are built-in. The high level of
                  finish is complemented by standard inclusions like porcelain
                  tiles, Franke sinks, quality carpets and stone bench tops.
                </p>
                <p>
                  We offer three ranges of single and double storey homes
                  designed to make a statement in terms of style, value and
                  inclusions.
                </p>
                <p>
                  We limit the number of homes we build and where we build them.
                </p>
              </Copy>
            </Box>
            <Box width={1 / 12} px={2} />
            <Box width={3 / 12} px={2}>
              <ResourceCategoryList />
            </Box>
          </Flex>
        </Row>

        <Row>
          <Flex>
            <Box width={1} px={2}>
              <Subtitle>More Resources</Subtitle>
            </Box>
          </Flex>
        </Row>

        <PageCards id="more" />
      </>
    )
  }
}

export default Page
