import React, { Component } from 'react';
import styled from 'styled-components';
import { theme } from '../util/style';
import { Link } from 'gatsby';

export default class ResourceCategoryList extends Component {
	render() {
		return (
			<List>
				<Item>
					<Link to="/resources">Resource Category 1</Link>
				</Item>
				<Item>
					<Link to="/resources">Resource Category 2</Link>
				</Item>
				<Item>
					<Link to="/resources">Resource Category 3</Link>
				</Item>
				<Item>
					<Link to="/resources">Resource Category 4</Link>
				</Item>
				<Item>
					<Link to="/resources">Resource Category 5</Link>
				</Item>
			</List>
		);
	}
}

const List = styled.div`
  border-top: 1px solid ${theme.colors.grey4};
  padding-top: 5px;
`;

const Item = styled.div`
	border-bottom: 1px solid ${theme.colors.grey4};
	margin-bottom: 10px;
  padding-bottom: 5px;
  a {
    color: ${theme.colors.grey1};
    &:hover {
      color: ${theme.colors.grey2};
    }
  }
`;
